.callout {
  position: relative;
  background-color: var(--sl-color-secondary-100);
  border-radius: var(--sl-border-radius-medium);
  box-shadow: var(--sl-shadow-large);
  min-width: 250px; /* find good size, make 100% for mobile */
  max-width: 500px;
}

.callout .label {
  position: absolute;
  left: -2px;
  top: calc(var(--sl-spacing-large) * -1);
}

.callout .body {
  display: flex;
  flex-direction: column;
  padding: var(--sl-spacing-large);
  padding-top: var(--sl-spacing-x-small);
  font-size: var(--sl-font-size-medium);
  color: var(--sl-color-primary);
}

.callout a {
  align-self: flex-end;
  color: var(--sl-color-primary);
  text-decoration-color: var(--sl-color-primary);
}

.callout.dark {
  border-top: 4px solid var(--sl-color-primary);
}

.callout.light {
  border-top: 4px solid var(--sl-color-tertiary);
}
