@keyframes appear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes disappear {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    display: none;
  }
}

.update-content .control-with-badges {
  display: flex;
}

.update-content .bar:first-child {
  position: sticky;
  top: 0;
  z-index: 1;
}

article.appear {
  animation-name: appear;
  animation-duration: 0.4s;
  animation-timing-function: linear;
}