.article--category-description {
  margin-top: 0;
  font-size: var(--sl-font-size-medium);
}

.article--category-links {
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
}

.article--category-links .link {
  padding: var(--sl-spacing-small) 0;
}