.article--control {
  display: flex;
  align-items: center;

  color: var(--sl-color-neutral-0);
}

.article--control sl-dropdown {
  color: currentColor;
  text-decoration: underline;

  display: flex;
  align-items: center;
  cursor: pointer;
}

.article--control sl-dropdown[placement=left-start] { margin-left: auto; }

.article--control div sl-icon {
  color: currentColor;
  margin-right: var(--sl-spacing-2x-small);
  vertical-align: middle;
}

.article--control .control-with-badges sl-dropdown + .badges {
  margin-left: var(--sl-spacing-medium);
}

.article--control .badge {
  background-color: var(--sl-color-tertiary);
  margin: 0 var(--sl-spacing-2x-small);
}

.article--control .badges .badge:first-child {
  margin-left: 0;
}

@media screen and (max-width: 48rem) {
  .article--control {
    align-items: flex-start;
  }

  .article--control .control-with-badges {
    flex-wrap: wrap;
  }

  .article--control .control-with-badges sl-dropdown + .badges {
    width: 100%;
    margin-top: var(--sl-spacing-medium);
    margin-left: 0;
  }

  .article--control sl-dropdown {
    flex-shrink: 0;
  }
}
