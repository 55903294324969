.link {
  display: flex;
  align-items: center;
}

.link span + sl-icon {
  margin-left: var(--sl-spacing-2x-small);
}

.link sl-icon + span {
  margin-left: var(--sl-spacing-2x-small);
}