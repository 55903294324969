/* Import the base Shoelace stylesheet: */
@import "@shoelace-style/shoelace/dist/themes/light.css";

@font-face {
  font-family: 'Bitter';
  src: url(/fonts/Bitter.ttf);
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  src: url(/fonts/OpenSans.ttf);
  font-display: swap;
}

::selection {
  color: #fff;
  background-color: var(--sl-color-tertiary);
}

:root {

  --body-background: #E3DCD0;
  --body-color: #1A202C;
  --heading-color: #10312B;
  --action-color: #10312B;

  --sl-font-serif: 'Bitter', serif;
  --sl-font-sans: 'Open Sans', sans-serif;

  --sl-color-primary: rgb(16, 49, 43);
  --sl-color-primary-50: rgb(249 250 249);
  --sl-color-primary-100: rgb(234 237 236);
  --sl-color-primary-200: rgb(218 223 222);
  --sl-color-primary-300: rgb(201 208 207);
  --sl-color-primary-400: rgb(177 188 186);
  --sl-color-primary-500: rgb(148 163 160);
  --sl-color-primary-600: rgb(117 136 132);
  --sl-color-primary-700: rgb(90 113 109);
  --sl-color-primary-800: rgb(68 94 89);
  --sl-color-primary-900: rgb(39 69 63);
  --sl-color-primary-950: rgb(14 43 38);

  --sl-color-secondary: rgb(188, 149, 92);
  --sl-color-secondary-50: rgb(251 249 246);
  --sl-color-secondary-100: rgb(243 235 225);
  --sl-color-secondary-200: rgb(233 220 202);
  --sl-color-secondary-300: rgb(223 204 177);
  --sl-color-secondary-400: rgb(209 182 143);
  --sl-color-secondary-500: rgb(191 154 99);
  --sl-color-secondary-600: rgb(159 126 78);
  --sl-color-secondary-700: rgb(131 104 64);
  --sl-color-secondary-800: rgb(108 85 53);
  --sl-color-secondary-900: rgb(77 61 37);
  --sl-color-secondary-950: rgb(47 37 23);

  --sl-color-tertiary: #235B4E;
  --sl-color-tertiary-50: rgb(248 250 249);
  --sl-color-tertiary-100: rgb(232 238 236);
  --sl-color-tertiary-200: rgb(214 224 222);
  --sl-color-tertiary-300: rgb(194 210 206);
  --sl-color-tertiary-400: rgb(169 191 185);
  --sl-color-tertiary-500: rgb(136 166 159);
  --sl-color-tertiary-600: rgb(100 140 130);
  --sl-color-tertiary-700: rgb(70 117 106);
  --sl-color-tertiary-800: rgb(45 98 86);
  --sl-color-tertiary-900: rgb(27 70 60);
  --sl-color-tertiary-950: rgb(17 43 37);
  
  --sl-color-neutral-100: #1A202C;

  --sl-breakpoint-sm: 30rem;
  --sl-breakpoint-md: 48rem;
  --sl-breakpoint-lg: 62rem;
  --sl-breakpoint-xl: 80rem;
  --sl-breakpoint-2xl: 96rem;
}

body {
  background: var(--body-background);
  color: var(--body-color);
  font-family: 'Open Sans';
  margin: 0;
}

a {
  font-family: 'Bitter';
  color: var(--sl-color-primary);
  text-decoration: underline;
  text-decoration-color: var(--sl-color-primary);
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Bitter';
  font-weight: 500;
  color: var(--sl-color-primary);
}

h2, h3, h4, h5, h6 {
  font-weight: 400;
}

article {
  font-size: var(--sl-font-size-large);
  padding: var(--sl-spacing-medium) 0;
}

main > .container:first-child {
  margin-top: 1rem;
}

.container {
  max-width: 62rem;
  margin: 0 auto;
}

.hidden { display: none; }

sl-menu { padding: 0; }

sl-menu-label {
  display: flex;
  align-items: center;
  color: var(--sl-color-primary);
  padding: var(--sl-spacing-small);
  font-family: 'Bitter';
  font-weight: 600;
  border-bottom: 2px solid var(--sl-color-primary);
}

sl-icon[name=calendar] { margin-bottom: 2px; }

sl-menu-label sl-icon {
  margin-right: var(--sl-spacing-2x-small);
}

sl-menu-item {
  display: block;
  color: var(--sl-color-primary);
  padding: var(--sl-spacing-small);
  transition: 0.2s linear;
  cursor: pointer;
  font-family: 'Bitter';
}

sl-menu-item:hover {
  background-color: var(--sl-color-secondary-100);
}

.badge {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: max(12px, 0.75em);
  font-weight: var(--sl-font-weight-semibold);
  letter-spacing: var(--sl-letter-spacing-normal);
  line-height: 1;
  border-radius: var(--sl-border-radius-small);
  white-space: nowrap;
  padding: 0.35em 0.6em;
  user-select: none;
  cursor: inherit;
  text-decoration: none;
}

.spacer {
  margin-top: var(--sl-spacing-large);
}

.decorated-title-callout {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: var(--sl-spacing-x-large);
}

.decorated-title-callout .decorated-title {
  margin-right: 1rem;
}

.decorated-title-callout .callout {
  max-width: 500px;
}

@media screen and (max-width: 62rem) {
  body, html { font-size: 14px; }

  .container {
    padding: 0 2rem;
  }

  sl-menu {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    min-height: 90%;
    height: 90%;
    box-shadow: 0 0 0 10rem rgba(0, 0, 0, 0.3);
  }
}

@media screen and (max-width: 48rem) {
  .decorated-title-callout {
    display: block;
  }

  .decorated-title-callout div + div {
    margin-left: 0;
  }

  .decorated-title-callout .decorated-title {
    margin-right: 0;
    margin-bottom: var(--sl-spacing-x-large);
  }

  .decorated-title-callout .callout { max-width: 100%; }
}