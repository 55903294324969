.label {
  display: inline-block;

  font-size: var(--sl-font-size-x-small);
  font-family: 'Bitter';

  padding: var(--sl-spacing-x-small);
  border-radius: var(--sl-border-radius-pill);
}

.label.dark {
  color: var(--sl-color-neutral-0);
  background-color: var(--sl-color-primary);
}

.label.light {
  color: var(--sl-color-neutral-0);
  background-color: var(--sl-color-tertiary);
}