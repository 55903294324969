.decorated-title h1 {
  font-size: var(--sl-font-size-4x-large);
  margin-top: 0;
  margin-bottom: var(--sl-spacing-2x-small);
}

.decoration {
  width: 400px;
}

.decoration svg {
  color: var(--sl-color-primary);
}

@media screen and (max-width: 62rem) {
  .decorated-title h1 {
    font-size: var(--sl-font-size-3x-large);
    margin-bottom: var(--sl-spacing-x-small);
  }

  .decoration { width: 100%; }

  .decoration svg {
    max-height: 24px;
  }
}