.article--recent-updates {
  color: #fff;
}

.article--recent-updates h2 {
  color: #fff;
  font-size: var(--sl-font-size-x-large);
  margin-top: 0;
}

.article--update-descriptions {
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  gap: var(--sl-spacing-2x-large) var(--sl-spacing-large);
  margin-top: var(--sl-spacing-2x-large);
}

@media screen and (max-width: 48rem) {
  .article--update-descriptions {
    display: block;
  }

  .article--update-descriptions .callout {
    margin-top: var(--sl-spacing-2x-large);
    max-width: 100%;
  }
}