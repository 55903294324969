footer {
  display: flex;
  align-items: center;
}

footer svg {
  width: 37px;
  height: 44px;
  color: var(--sl-color-primary);
}

footer ul {
  list-style-type: none;
  padding-left: var(--sl-spacing-medium);
}

footer ul li {
  line-height: var(--sl-line-height-dense);
}

@media screen and (max-width: 62rem) {
  footer svg {
    height: 24px;
    width: 18px;
  }

  footer ul {
    padding-left: 0;
  }

  footer ul li {
    float: left;
    padding: 1rem var(--sl-spacing-small);
  }
}