.article--categories {
  margin-top: var(--sl-spacing-large);
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  gap: var(--sl-spacing-large);
}

@media screen and (max-width: 62rem) {
  .article--categories {
    grid-template-columns: minmax(0, 1fr);
  }
}
