.card {
  background-color: var(--sl-color-secondary-100);
  border-radius: var(--sl-border-radius-medium);
  box-shadow: var(--sl-shadow-large);
}

.card h2 {
  display: flex;
  margin: 0;
  color: #fff;
  padding: var(--sl-spacing-small);
  font-size: var(--sl-font-size-x-large);
  background-color: var(--sl-color-primary);
  border-top-left-radius: var(--sl-border-radius-medium);
  border-top-right-radius: var(--sl-border-radius-medium);

}

.card h2 svg {
  height: var(--sl-font-size-medium);
  margin-right: var(--sl-spacing-2x-small);
  margin-top: 0.4rem;
  flex-shrink: 0;
}

.card h2 svg .buckle--fill-rect {
  fill: var(--sl-color-primary);
}

.card .card--content {
  padding: var(--sl-spacing-small);
}