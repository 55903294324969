header {
  margin-bottom: var(--sl-spacing-x-large);
}

header .logo {
  color: var(--sl-color-primary);
  width: 113px;
  height: 36px;
}

header .logo {
  transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1);
}

header .logo:hover {
  color: var(--sl-color-tertiary);
}
