.article--navigation {
  display: flex;
  justify-content: space-between;
}

.article--navigation a {
  color: #fff;
}

.article--navigation sl-icon {
  color: #fff;
  vertical-align: middle;
}